@tailwind base;

a {
  @apply text-blue-700;
}

a:hover {
  @apply border-b-2 border-blue-700;
}

@tailwind components;

@tailwind utilities;

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}
